import {
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Container,
  withStyles,
  ClickAwayListener,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useStateValue } from '../../../providers/StateProvider';
import imageLoader from '../../../utils/imageLoader';
import IconSelection from '../../modals/IconSelection';
import stationNameConverter from '../../../utils/stationNameConverter';
import { COLORS } from '../../../constants';
import usePicks from '../../stations/Exception/hooks/usePicks';

// Icons import

const icons = imageLoader.importImages();
const { darkGray, orange, white } = COLORS;

const MenuItemStyled = withStyles({
  root: {
    textTransform: 'uppercase',
    '& span': {
      whiteSpace: 'normal',
      fontFamily: 'Montserrat !important',
      fontWeight: 'bold',
      fontSize: '14px',
      padddingLeft: '16px',
    },
    color: darkGray,
    '& .MuiListItemIcon-root': {
      padding: '4px 0 px 16px',
      '& img': {
        filter: 'opacity(0.6)',
        width: '24px',
      },
    },

    '&:visited': {
      color: darkGray,
    },
    '&:active': {
      backgroundColor: orange,
      color: white,
      '& .MuiListItemIcon-root': {
        color: white,
        '& img': {
          filter: 'invert(1)',
        },
      },
    },
    '&:hover': {
      backgroundColor: orange,
      color: white,
      '& .MuiListItemIcon-root': {
        color: white,
        '& img': {
          filter: 'invert(1)',
        },
      },
    },
    '&$selected': {
      backgroundColor: orange,
      color: white,
      '& .MuiListItemIcon-root': {
        color: white,
        '& img': {
          filter: 'invert(1)',
        },
      },
    },
    '&$selected:hover': {
      backgroundColor: orange,
      color: white,
      '& .MuiListItemIcon-root': {
        color: white,
        '& img': {
          filter: 'invert(1)',
        },
      },
    },
  },
  selected: {},
  gutters: {
    paddingLeft: '0px',
    paddingRight: '4px',
  },
})(MenuItem);

const ListItemIconStyled = withStyles({
  root: {
    paddingLeft: '16px',
    minWidth: '24px',
  },
})(ListItemIcon);

const ListItemTextStyled = withStyles({
  root: {
    fontWeight: 'bold',
    paddingLeft: '16px',
  },
})(ListItemText);

const StationSelectionContainer = ({ border, history, toggleDrawer }) => {
  const [{ userStationList, partnerLocation, roleList }] = useStateValue();
  const [selectionState, setSelectionState] = useState(false);
  const { hasPendingPicks } = usePicks();

  const selectionView = () => {
    setSelectionState(() => !selectionState);
  };
  const routerHistory = useHistory();

  const { t } = useTranslation();

  const getStationIcon = (name) => {
    const importName = `./${name}.svg`;
    return icons(importName);
  };

  // Filtering PSNAD as a station.
  // Entry point will be through Exception page of Handbag hubs only
  // Also filter FulfillmentPick station. Entrypoint is through IMS
  const makeItemList = () =>
    userStationList
      .sort((a, b) => a.id - b.id)
      .filter(
        (station) =>
          station.name !== 'PSNAD' &&
          station.name !== 'PreDispatch' &&
          station.name !== 'FulfillmentPick'
      )
      .map((station) => ({
        id: station.id,
        customEndpoint: station.customEndpoint,
        isExternalUrl: station.isExternalUrl,
        name: t(
          `StationsNames.${stationNameConverter(
            station.name,
            partnerLocation.categoryName,
            roleList
          )}`
        ),
        icon: getStationIcon(station.name),
        alert:
          station.name === 'WarehouseManagement'
            ? hasPendingPicks
            : false,
      }));

  const itemArray = makeItemList();

  const handleClick = (item) => {
    if (border) selectionView();

    let url = `/stations/${item.id}`;
    if (item.customEndpoint) url = item.customEndpoint;

    if (item.isExternalUrl) {
      const { location } = window;
      location.assign(location.origin + url);
    } else if (history) {
      history.push(url);
    } else {
      routerHistory.push(url);
    }
  };

  if (!border)
    return (
      <StationContainer component="main" maxWidth="xl">
        <ClickAwayListener onClickAway={border ? selectionView : () => null}>
          <IconSelection
            title={
              border ? t('StationSelectionContainer.SelectStationHeader') : ''
            }
            iconSize={4}
            logo={!border}
            itemList={makeItemList()}
            onClick={handleClick}
          />
        </ClickAwayListener>
      </StationContainer>
    );
  return (
    <MenuList onClick={toggleDrawer} disablePadding>
      {itemArray.map((station) => (
        <MenuItemStyled onClick={() => handleClick(station)} key={station.id}>
          <ListItemIconStyled>
            <img src={station.icon} alt={station.name} />
          </ListItemIconStyled>
          <ListItemTextStyled variant="inherit">
            {station.name}
          </ListItemTextStyled>
          {/* <ListItemIcon /> */}
        </MenuItemStyled>
      ))}
    </MenuList>
  );
};

export default StationSelectionContainer;

StationSelectionContainer.defaultProps = {
  border: false,
  history: undefined,
  toggleDrawer: undefined,
};

StationSelectionContainer.propTypes = {
  border: PropTypes.bool,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }),
  toggleDrawer: PropTypes.func,
};

// Style
const StationContainer = styled(Container)`
  position: relative;
  align-self: start;
  width: fit-content;
  background-color: ${COLORS.white};
  padding: 20px;

  ${(props) =>
    props.border
      ? `
          margin: 1.5em;
          border: 1px solid darkgray;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          max-height: calc(100vh - 1.5em * 2);
          max-width: 400px;
          min-width: 178px;
          overflow: auto;
        `
      : `
          margin: auto;
          max-height: calc(100% - 100px);
          top: 100px;
        `}
`;
